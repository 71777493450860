import { Rfq } from "ui/src/types"
import cn from 'classnames';
import * as itemStyles from '../Item.module.scss';
import { useEffect, useMemo, useState } from "react";

type RfqSplitOrderProps = {
    rfq: Rfq;
    onSplitOrderChange: () => void;
}

const translations = window.app.preloadState.translation;

export const RfqSplitOrder = ({ rfq, onSplitOrderChange }: RfqSplitOrderProps) => {

    const [isRfqSplitted, setIsRfqSplitted] = useState<boolean>(rfq.shouldSplitRfqOrder);

    const onChange = async (checked: boolean) => {
        const response = await fetch(`/api/cart/rfq/split`, {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            body: JSON.stringify(!checked),
            method: "PUT"
        });
        if (!response.ok) {
            console.error(await response.text());
        }
        onSplitOrderChange();
    }

    const updateRfqSplitValue = (isChecked: boolean) => {
        setIsRfqSplitted(isChecked);
        onChange(!isChecked);
    }

    const hasNote = useMemo(() => !!rfq.note?.trim(), [rfq.note]);

    useEffect(() => {
        if (hasNote) {
            updateRfqSplitValue(false);
        }
    }, [hasNote]);


    if (!rfq.hasItemWithPrice) {
        return <></>;
    }
    if (!rfq.hasItemWithoutPrice) {
        return <></>;
    }
    if (hasNote) {
        return <></>;
    }

    return (<div className={cn(itemStyles.rfqnote)}>
        <p>
            {translations["checkout.rfqDoNotSplitOrder"]}
        </p>
        <input id={"rfqDoNotSplitOrderYes"} type="radio" checked={!isRfqSplitted} onChange={() => updateRfqSplitValue(false)} />
        <label htmlFor={"rfqDoNotSplitOrderYes"}>{translations["checkout.rfqDoNotSplitOrderYesAnswer"]}</label>
        <input id={"rfqDoNotSplitOrderNo"} type="radio" checked={isRfqSplitted} onChange={() => updateRfqSplitValue(true)} />
        <label htmlFor={"rfqDoNotSplitOrderNo"}>{translations["checkout.rfqDoNotSplitOrderNoAnswer"]}</label>
    </div>);
}