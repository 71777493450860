import { InputPhone, InputText, debounce } from "ui";
import { BillingInformation } from "ui/src/types";
import * as styles from './BillingInfo.module.scss';
import { useEffect, useState } from "react";

const debouncedUpdate = debounce(async (customerEmail: string, customerPhoneNumber: string, customerPurchaseOrderNumber: string, customerOrderReference: string) => {
    const response = await fetch(`/api/cart/update`, {
        headers: {
            "Content-Type": "application/json",
            "Swecon-Current-Language": window.app.preloadState.currentLanguage
        },
        body: JSON.stringify({
            customerEmail,
            customerPhoneNumber,
            customerPurchaseOrderNumber,
            customerOrderReference
        }),
        method: "POST"
    });
}, 500);

export const BillingInfo = ({ billingSummary, billingInfoChanged, isReferenceFieldHidden }: { billingSummary: BillingInformation, billingInfoChanged: (b: Partial<BillingInformation>) => void, isReferenceFieldHidden: boolean }) => {
    const emailRegex = new RegExp(billingSummary.emailRegex);
    const referenceRegex = /^.{1,35}$/g;

    const translations = window.app.preloadState.translation;

    const [email, setEmail] = useState(billingSummary.customerEmail ?? "");
    const [phoneNo, setPhoneNo] = useState(billingSummary.customerPhoneNumber ?? "");

    const [purchaseNumber, setPurchaseNumber] = useState(billingSummary.customerPurchaseOrderNumber ?? "");
    const [customerReference, setCustomerReference] = useState(billingSummary.customerOrderReference ?? "");

    const [customerReferenceValidation, setCustomerReferenceValidation] = useState<string>();
    const [emailValidation, setEmailValidation] = useState<string>();
    const [phoneNoValidation, setPhoneNoValidation] = useState<string>();

    const updateCustomerReferenceValidation = (reference: string) => {
        setCustomerReferenceValidation(referenceRegex.test(reference) || !reference ? undefined: translations["checkout.customerReferenceIsInvalid"]);
    }

    const updateEmailValidation = (email: string) => {
        setEmailValidation(emailRegex.test(email) || !email ? undefined : translations["checkout.customerEmailIsInvalid"]);
    }
    const updatePhoneNoValidation = (phoneNo: string) => {
        setPhoneNoValidation(!!phoneNo ? undefined : translations["checkout.customerPhoneNumberIsInvalid"]);
    }

    const [purchaseNumberValidation, setPurchaseNumberValidation] = useState<string>();
    const updatePurchaseNumberValidation = (purchaseNumber: string) => {
        if (!billingSummary.isCustomerPurchaseOrderNumberRequired) {
            setPurchaseNumberValidation(undefined);
        } else {
            setPurchaseNumberValidation(!!purchaseNumber ? undefined : translations["checkout.customerPurchaseOrderNumberIsRequired"]);
        }
    }

    const updateCart = (setter: (_: string) => void) => (ev: React.FormEvent<HTMLInputElement>) => setter(ev.currentTarget.value);

    useEffect(() => {
        billingInfoChanged({ customerEmail: email, customerPhoneNumber: phoneNo, customerPurchaseOrderNumber: purchaseNumber, customerOrderReference: customerReference });
        if ((emailRegex.test(email) || !email) && (!!phoneNo) && (!billingSummary.isCustomerPurchaseOrderNumberRequired || !!purchaseNumber)) {
            debouncedUpdate(email, phoneNo, purchaseNumber, customerReference);
        }
    }, [email, phoneNo, purchaseNumber, customerReference]);

    return (
        <>
            <h3 className={styles.title}>{translations["checkout.billing.Title"]}</h3>
            <div className={styles.twoColumns} data-matomo-mask>
                <div className={styles.personalInformation}>
                    <InputText
                        name="emailAddress"
                        label={translations["checkout.enterEmailForDeliveryInformation"]}
                        value={email}
                        onChange={updateCart(setEmail)}
                        onBlur={ev => updateEmailValidation(ev.currentTarget.value)}
                        error={emailValidation}
                    />
                    <InputPhone
                        name="phoneNumber"
                        label={translations["checkout.enterPhoneNumberForDeliveryInformation"]}
                        value={phoneNo}
                        onChange={setPhoneNo}
                        onBlur={(ev) => updatePhoneNoValidation(ev.currentTarget.value)}
                        error={phoneNoValidation}
                    />
                    <InputText
                        name="purchaseNumber"
                        label={translations["checkout.enterYourOwnPurchaseNumber"]}
                        value={purchaseNumber}
                        onChange={updateCart(setPurchaseNumber)}
                        onBlur={ev => updatePurchaseNumberValidation(ev.currentTarget.value)}
                        error={purchaseNumberValidation}
                    />

                    {!isReferenceFieldHidden && 
                        <InputText
                            name="customerReference"
                            label={translations["checkout.enterYourOwnReference"]}
                            value={customerReference}
                            onChange={updateCart(setCustomerReference)}
                            onBlur={ev => updateCustomerReferenceValidation(ev.currentTarget.value)}
                            error={customerReferenceValidation}
                        />
                    }
                    
                </div>
                {billingSummary.billingAddress && <div className={styles.address}>
                    <h5>{translations["checkout.billing.InvoiceAddress"]}</h5>
                    <div>{billingSummary.billingAddress.name}</div>
                    <div>{billingSummary.billingAddress.streetAndHouse}</div>
                    <div>{billingSummary.billingAddress.city}</div>
                    <div>{billingSummary.billingAddress.country}</div>
                    <div>{billingSummary.billingAddress.postalCode}</div>
                </div>
                }
            </div>
        </>
    );
}