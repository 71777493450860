export const groupBy = <T, V>(data: T[], getter: (v: T) => V): { key: V, items: T[] }[] => {
    const groupMap = new Map<V, T[]>();
    data.forEach(d => {
        const value = getter(d);
        if (!groupMap.has(value)) {
            groupMap.set(value, []);
        }
        groupMap.get(value)!.push(d);
    });
    return Array.from(groupMap).map(g => ({ key: g[0], items: g[1] }));
}

export const dedup = <T, V>(data: T[], getter: (v: T) => V): T[] => {
    const dedupMap = new Map<V, T>();
    data.forEach(d => dedupMap.set(getter(d), d));
    return Array.from(dedupMap).map(p => p[1]);
};

export const partition = <T>(data: T[], predicate: (v: T) => boolean): [T[], T[]] => {
    const groups = groupBy(data, predicate);
    return [
        groups.find(g => g.key)?.items ?? [],
        groups.find(g => !g.key)?.items ?? []
    ];
}